import "./SuggestedResponse.scss";

import React from "react";

import { ReactComponent as AIIcon } from "../../assets/icons/openai-icon.svg";
import { ReactComponent as ThumbsDownIcon } from "../../assets/icons/thumbs-down.svg";
import Button from "../Button";
import { ErrorMessageContainer } from "../ErrorMessageContainer";
import Loading from "../Loading";

const SuggestedResponse = ({
  loading,
  error,
  setValue,
  handleThumbsDown,
  thumbsDownLoading,
  thumbsDownError,
  isSuggestedResponseUsed
}) => {
  return (
    <div className="SuggestedResponse">
      <Button
        type="button"
        onClick={setValue}
        title="Suggested response"
        className="SuggestedResponse__button"
        disabled={loading}
      >
        <AIIcon className="SuggestedResponse__icon" />
        {loading && <Loading className="SuggestedResponse__loader" />}
      </Button>
      {isSuggestedResponseUsed && (
        <Button
          type="button"
          onClick={handleThumbsDown}
          title="Mark the AI suggestion as not helpful"
          className="SuggestedResponse__button"
          disabled={thumbsDownLoading}
        >
          <ThumbsDownIcon className="SuggestedResponse__icon" />
          {thumbsDownLoading && (
            <Loading className="SuggestedResponse__loader" />
          )}
        </Button>
      )}

      {(error || thumbsDownError) && (
        <div className="SuggestedResponse__error">
          <ErrorMessageContainer>
            An error occurred, please try again later
          </ErrorMessageContainer>
        </div>
      )}
    </div>
  );
};

export default SuggestedResponse;
