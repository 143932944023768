import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useLocalStorageSuggestedResponse } from "./useLocalStorageSuggestedResponse";
import { useLocalStorageSuggestedResponseTracker } from "./useLocalStorageSuggestedResponseTracker";
import { securelyParseJson } from "./utils";

const draftExpirationTime = 18 * 60 * 60 * 1000; // 18 hours in ms
const draftKeyBase = `airbox:draft-message:`;
const conversationIdPrefix = "conversation-id:";
const threadIdPrefix = "thread-id:";

export const useLocalStorageDraft = ({
  state: { draftLoaded, cannedMessages, value },
  onDraftLoad,
  onDraftRestore,
  isModified
}) => {
  const { conversationId, threadId } = useParams();

  const prefixedId = conversationId
    ? `${conversationIdPrefix}${conversationId}`
    : `${threadIdPrefix}${threadId}`;
  const draftKey = `${draftKeyBase}${prefixedId}`;

  const { removeSuggestedResponse } = useLocalStorageSuggestedResponse();
  const {
    setIsSuggestedResponseUsed,
    removeSuggestedResponseTracker
  } = useLocalStorageSuggestedResponseTracker();

  const removeDraft = id => {
    window.localStorage.removeItem(
      `${draftKeyBase}${conversationIdPrefix}${id}`
    );
    window.localStorage.removeItem(`${draftKeyBase}${threadIdPrefix}${id}`);
  };

  const removeOldDrafts = () => {
    const oldDrafts = [];

    for (let i = 0; i < window.localStorage.length; i++) {
      const key = window.localStorage.key(i);
      if (
        key.startsWith(draftKeyBase) &&
        securelyParseJson(window.localStorage.getItem(key))?.timestamp <
          Date.now() - draftExpirationTime
      ) {
        oldDrafts.push(key.slice(key.lastIndexOf(":") + 1));
      }
    }

    oldDrafts.forEach(id => {
      removeDraft(id);
      removeSuggestedResponse(id);
      removeSuggestedResponseTracker(id);
    });
  };

  useEffect(() => {
    // the removeOldDrafts is needed because of QuotaExceededError experienced by some users
    removeOldDrafts();
    // empty dependencies so that the effect is only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Ask user if he wants to restore the draft and remove it from local storage
  useEffect(() => {
    if (draftLoaded) {
      return;
    }

    // the drafts created before 31.12.2025 are just a string (don't have timestamp)
    const draftMessage = (() => {
      const localStorageValue = securelyParseJson(
        window.localStorage.getItem(draftKey)
      );

      if (typeof localStorageValue === "string") {
        return localStorageValue;
      }

      return localStorageValue?.value;
    })();

    if (draftMessage) {
      if (
        window.confirm(
          "There is a draft saved for this thread. Would you like to restore it?"
        )
      ) {
        onDraftRestore(draftMessage);
      } else {
        setIsSuggestedResponseUsed(false);
      }
      window.localStorage.removeItem(draftKey);
    }
    onDraftLoad();
  }, [
    onDraftLoad,
    onDraftRestore,
    draftKey,
    draftLoaded,
    setIsSuggestedResponseUsed
  ]);

  // Saves current message to local storage as a draft
  useEffect(() => {
    // Don't modify local storage until user decided if he wants to restore it
    if (!draftLoaded) {
      return;
    }

    // Keep message in local storage if it's custom or if there are any canned messages
    if (isModified || cannedMessages.length > 0) {
      const valueWithTimestamp = {
        timestamp: Date.now(),
        value
      };
      window.localStorage.setItem(draftKey, JSON.stringify(valueWithTimestamp));
    } else {
      window.localStorage.removeItem(draftKey);
    }
  }, [cannedMessages.length, draftKey, draftLoaded, isModified, value]);
};
