import axios from "axios";
import get from "lodash/get";

import { AIRBOX_STAGING_URL } from "../utils/constants";
import { isRunningInFront } from "./front";
import { auth } from "./GoogleAuth";

let authSecret = "";

export const isStaging = () => {
  return window.location.host === AIRBOX_STAGING_URL;
};

export const airlinkAPI = axios.create({
  baseURL: isStaging()
    ? process.env.REACT_APP_AIRLINK_API_STAGING
    : process.env.REACT_APP_AIRLINK_API,
  headers: {
    common: {
      "Content-Type": "application/json"
    }
  }
});

airlinkAPI.interceptors.request.use(config => {
  if (isRunningInFront) {
    config.headers["x-front-auth-secret"] = authSecret;
    config.headers["x-front-user-email"] = get(window.Front, "user.email", "");
    return config;
  } else {
    return auth
      .getAuthToken()
      .then(token => {
        config.headers["x-google-auth-token"] = token;
        return config;
      })
      .catch(() => {
        return config;
      });
  }
});

export const registerAuthInterceptors = showNotificationCallback => {
  airlinkAPI.interceptors.response.use(
    response => response,
    errorObj => {
      const errorDetails = get(errorObj, "response.data", null);

      if (!errorDetails) {
        return Promise.reject(errorObj);
      }

      const { status_code, error } = errorDetails;

      if (status_code === 401) {
        auth.signOut().then(() => {
          showNotificationCallback(error);
        });
      }

      return Promise.reject(errorObj);
    }
  );
};

export const setAuthSecret = queryString => {
  if (process.env.NODE_ENV === "test") {
    return;
  }

  // For access to Airbox (Standalone) App
  if (queryString.includes("auth_secret")) {
    const qs = new URLSearchParams(queryString);
    authSecret = qs.get("auth_secret");
  } else {
    console.warn(
      "Auth secret not found - make sure you are running Airbox in Frontapp or manually add the secret."
    );
  }
};

export const getMailBox = (mailBoxName, listingId, cancelToken) => {
  return airlinkAPI.get(`/airbox/accounts/${mailBoxName}/threads`, {
    params: {
      listing_id: listingId
    },
    cancelToken
  });
};

export const getPropertyInfo = (
  mailBoxName,
  threadId,
  homeCode,
  cancelToken
) => {
  return airlinkAPI.get(
    `/airbox/accounts/${mailBoxName}/threads/${threadId}/property-info`,
    {
      params: {
        home_code: homeCode
      },
      cancelToken
    }
  );
};

export const getAirbnbInfo = (mailBoxName, threadId, cancelToken) => {
  return airlinkAPI.get(
    `/airbox/accounts/${mailBoxName}/threads/${threadId}/airbnb-info`,
    { cancelToken }
  );
};

export const getSentiment = airbnbGuestId =>
  airlinkAPI.get(`/airbox/guest_sentiment/${airbnbGuestId}`);

export const getReservation = (
  mailBoxName,
  confirmationCode,
  threadId,
  cancelToken
) => {
  return airlinkAPI.get(
    `/airbox/accounts/${mailBoxName}/reservations/${confirmationCode}`,
    {
      params: {
        thread_id: threadId
      },
      cancelToken
    }
  );
};

export const getBookingsByParams = (params, cancelToken) => {
  return airlinkAPI.get("/airbox/search/bookings", {
    params: params,
    cancelToken
  });
};

export const getBookingsyncConversation = (conversationId, cancelToken) => {
  return airlinkAPI.get(`/airbox/conversation/${conversationId}`, {
    cancelToken
  });
};

export const sendBookingsyncMessage = (conversationId, senderId, message) => {
  return airlinkAPI.post(`/airbox/conversation/${conversationId}/message`, {
    sender_id: senderId,
    message
  });
};

export const getPricing = (mailBoxName, threadId, cancelToken) => {
  return airlinkAPI.get(
    `/airbox/accounts/${mailBoxName}/threads/${threadId}/pricing`,
    {
      cancelToken
    }
  );
};

export const getGuest = (mailBoxName, userId, cancelToken) => {
  return airlinkAPI.get(`/airbox/accounts/${mailBoxName}/users/${userId}`, {
    cancelToken
  });
};

// Send Airbnb message
export const sendAirbnbMessage = (
  mailBoxName,
  threadId,
  message,
  aiGeneratedMessage = {}
) => {
  const operator_email =
    get(window.Front, "user.email", "") ||
    get(auth?.getUserProfile(), "email", "");

  return airlinkAPI.post(
    `/airbox/accounts/${mailBoxName}/threads/${threadId}/messages`,
    {
      message,
      operator_email,
      airbox_url: window.location.href,
      ...aiGeneratedMessage
    }
  );
};

// Search properties by home code
export const searchProperties = params => {
  return airlinkAPI.get(`/airbox/search`, { params: params });
};

// Flagging guests
export const flagGuest = data => {
  return airlinkAPI.post("/airbox/guest_flag", data);
};

export const removeFlag = flagId => {
  return airlinkAPI.delete(`/airbox/guest_flag/${flagId}`);
};

// Booking actions
export const preApproveEnquiry = (mailBoxName, threadId) => {
  return airlinkAPI.post(
    `/airbox/accounts/${mailBoxName}/threads/${threadId}/preapprove`
  );
};

export const denyEnquiry = (mailBoxName, threadId) => {
  return airlinkAPI.post(
    `/airbox/accounts/${mailBoxName}/threads/${threadId}/deny`
  );
};

export const acceptReservation = (mailBoxName, confirmationCode, threadId) => {
  return airlinkAPI.post(
    `/airbox/accounts/${mailBoxName}/reservations/${confirmationCode}/accept`,
    {
      thread_id: threadId
    }
  );
};

export const declineReservation = (mailBoxName, confirmationCode, threadId) => {
  return airlinkAPI.post(
    `/airbox/accounts/${mailBoxName}/reservations/${confirmationCode}/decline`,
    {
      thread_id: threadId
    }
  );
};

export const cancelReservation = (
  mailBoxName,
  confirmationCode,
  cancellationData
) => {
  return airlinkAPI.post(
    `/airbox/accounts/${mailBoxName}/reservations/${confirmationCode}/cancel`,
    {
      cancellation_data: cancellationData
    }
  );
};

export const acceptAlteration = (mailBoxName, alterationId, threadId) => {
  return airlinkAPI.post(
    `/airbox/accounts/${mailBoxName}/alterations/${alterationId}/accept`,
    {
      thread_id: threadId
    }
  );
};

export const declineAlteration = (mailBoxName, alterationId, threadId) => {
  return airlinkAPI.post(
    `/airbox/accounts/${mailBoxName}/alterations/${alterationId}/decline`,
    {
      thread_id: threadId
    }
  );
};

export const withdrawAlteration = (mailBoxName, alterationId, threadId) => {
  return airlinkAPI.post(
    `/airbox/accounts/${mailBoxName}/alterations/${alterationId}/cancel`,
    {
      thread_id: threadId
    }
  );
};

export const createAlteration = (
  mailBoxName,
  confirmationCode,
  threadId,
  data
) => {
  return airlinkAPI.post(
    `/airbox/accounts/${mailBoxName}/reservations/${confirmationCode}/create_alteration`,
    {
      thread_id: threadId,
      data
    }
  );
};

export const updateReservation = (bookingId, data) => {
  return airlinkAPI.patch(`/airbox/bookings/${bookingId}`, data);
};

export const getPriceQuote = (mailBoxName, confirmationCode, params) => {
  return airlinkAPI.get(
    `/airbox/accounts/${mailBoxName}/reservations/${confirmationCode}/price_quote`,
    {
      params: params
    }
  );
};

export const getCannedResponses = ({ localityName, bookingId, propertyId }) => {
  // TODO: `locality` param to be deprecated once airlink updated
  return airlinkAPI.get("/airbox/canned", {
    params: {
      locality: localityName,
      booking_id: bookingId,
      property_id: propertyId
    }
  });
};

export const getMaintenance = (maintenanceId, cancelToken) => {
  return airlinkAPI.get(`/maintenances/${maintenanceId}`, cancelToken);
};

export const postMaintenance = maintenanceIssue => {
  return airlinkAPI.post(`/maintenances/`, maintenanceIssue);
};

export const patchMaintenance = (maintenanceId, maintenanceIssue) => {
  return airlinkAPI.patch(`/maintenances/${maintenanceId}`, maintenanceIssue);
};

export const getSuggestedResponse = ({
  bookingId,
  messages,
  propertyId,
  guestName
}) => {
  return airlinkAPI.post(`/airbox/open_ai/suggested_response`, {
    property_id: propertyId,
    booking_id: bookingId,
    messages: messages,
    guest_name: guestName
  });
};

export const postSuggestedResponseThumbsDown = ({
  suggestedResponseRecordId
}) => {
  return airlinkAPI.patch(
    `/airbox/ai_performance_record/${suggestedResponseRecordId}`,
    {
      accepted_by_user: false
    }
  );
};
