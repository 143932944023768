import "./Messenger.scss";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState
} from "react";

import {
  ACTION_ADD_CANNED_MESSAGE,
  ACTION_DRAFT_LOADED,
  ACTION_RESET,
  ACTION_SET_VALUE,
  createInitialState,
  reducer
} from "./reducer";
import { useLocalStorageDraft } from "./useLocalStorageDraft";
import { useLocalStorageSuggestedResponse } from "./useLocalStorageSuggestedResponse";
import { useLocalStorageSuggestedResponseTracker } from "./useLocalStorageSuggestedResponseTracker";
import usePostThumbsDown from "./usePostThumbsDown";
import { possibleEmptyTemplates } from "./utils";

/**
 * HOC – all the shared logic for both AirbnbMessenger and BookingSyncMessenger.
 */
const withMessenger = WrappedComponent => {
  const WithMessenger = props => {
    const { booking, guestName, property, thread } = props;
    const {
      loading,
      error,
      getSuggestedResponse,
      getSuggestedResponseRecordId
    } = useLocalStorageSuggestedResponse(thread, booking, property, guestName);

    const {
      getIsSuggestedResponseUsed,
      setIsSuggestedResponseUsed,
      resetIsSuggestionsResponseUsed
    } = useLocalStorageSuggestedResponseTracker();

    const [reducerState, dispatch] = useReducer(
      reducer,
      { property, guestName },
      createInitialState
    );

    const {
      postThumbsDown,
      loading: thumbsDownLoading,
      error: thumbsDownError
    } = usePostThumbsDown();

    const [isModified, setIsModified] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isDraftRestored, setIsDraftRestored] = useState(false);
    const [cannedMessageValue, setCannedMessageValue] = useState("");
    const setValue = useCallback(
      value => dispatch({ type: ACTION_SET_VALUE, value }),
      []
    );
    const value = useMemo(() => reducerState.value, [reducerState.value]);
    const setSuggestedValue = useCallback(() => {
      // get suggested response only if the last message is a guest message
      if (thread?.posts[0]?.is_guest) {
        dispatch({ type: ACTION_SET_VALUE, value: getSuggestedResponse() });

        setIsSuggestedResponseUsed(true);
      }
    }, [getSuggestedResponse, setIsSuggestedResponseUsed, thread?.posts]);

    useLocalStorageDraft({
      state: {
        draftLoaded: reducerState.draftLoaded,
        cannedMessages: reducerState.cannedMessages,
        value
      },
      onDraftLoad: () => dispatch({ type: ACTION_DRAFT_LOADED }),
      onDraftRestore: draftMessage => {
        setValue(draftMessage);
        setIsDraftRestored(true);
      },
      isModified
    });

    const handleCannedMessageSelection = useCallback(
      ({ value }) => {
        setCannedMessageValue(value);
        dispatch({ type: ACTION_ADD_CANNED_MESSAGE, value });
      },
      [setCannedMessageValue]
    );

    const handleTextareaFocus = useCallback(() => {
      setIsExpanded(true);
    }, [setIsExpanded]);

    const handleReset = useCallback(() => {
      dispatch({ type: ACTION_RESET });
      setCannedMessageValue("");
      resetIsSuggestionsResponseUsed();
    }, []);

    // Updates the isModified state on value changes
    // TODO: would be best to have this in the reducer
    useEffect(() => {
      const valueIsNotOneOfEmptyTemplates = !possibleEmptyTemplates(
        property,
        guestName
      ).includes(value);

      setIsModified(valueIsNotOneOfEmptyTemplates);
    }, [guestName, handleReset, property, reducerState.cannedMessages, value]);

    const getAiGeneratedMessage = () => {
      const usedAiResponse = getIsSuggestedResponseUsed();
      const aiResponse = getSuggestedResponse();
      const aiResponseRecordId = getSuggestedResponseRecordId();

      return usedAiResponse && aiResponse
        ? {
            ai_generated_message: aiResponse,
            ai_performance_record_id: aiResponseRecordId
          }
        : {};
    };

    const getDevConfirmation = () => {
      if (process.env.NODE_ENV === "development") {
        return window.confirm(
          "Dear dev, are you really sure you want to do this?"
        );
      }

      return true;
    };

    // Prefill message with suggested response
    useEffect(() => {
      const isDraftLoaded = reducerState.draftLoaded;

      if (!isDraftLoaded || isDraftRestored || loading) {
        return;
      }

      const isSuggestedResponseUsed = getIsSuggestedResponseUsed();

      if (isSuggestedResponseUsed) {
        return;
      }

      setSuggestedValue();
    }, [
      loading,
      reducerState.draftLoaded,
      isDraftRestored,
      getIsSuggestedResponseUsed
    ]);

    const handleThumbsDown = useCallback(() => {
      postThumbsDown(getSuggestedResponseRecordId());
    }, [postThumbsDown, getSuggestedResponseRecordId]);

    const messengerUIProps = useMemo(
      () => ({
        booking,
        cannedMessageValue,
        dispatch,
        handleCannedMessageSelection,
        handleReset,
        handleTextareaFocus,
        isExpanded,
        isModified,
        property,
        setIsExpanded,
        setSuggestedValue,
        setValue,
        thread,
        value,
        suggestedResponseLoading: loading,
        suggestedResponseError: error,
        handleThumbsDown,
        thumbsDownLoading,
        thumbsDownError,
        isSuggestedResponseUsed: getIsSuggestedResponseUsed()
      }),
      [
        booking,
        cannedMessageValue,
        dispatch,
        handleCannedMessageSelection,
        handleReset,
        handleTextareaFocus,
        isExpanded,
        isModified,
        property,
        setIsExpanded,
        setSuggestedValue,
        setValue,
        thread,
        value,
        loading,
        error,
        handleThumbsDown,
        thumbsDownLoading,
        thumbsDownError,
        getIsSuggestedResponseUsed
      ]
    );

    return (
      <WrappedComponent
        getDevConfirmation={getDevConfirmation}
        getAiGeneratedMessage={getAiGeneratedMessage}
        guestName={guestName}
        handleReset={handleReset}
        messengerUIProps={messengerUIProps}
        message={reducerState.value}
        {...props}
      />
    );
  };

  WithMessenger.displayName = `withMessenger(${WrappedComponent.displayName ||
    WrappedComponent.name})`;

  return WithMessenger;
};

export default withMessenger;
