import { useContext, useState } from "react";

import { AppContext } from "../../data/AppContextContainer";
import { postSuggestedResponseThumbsDown } from "../../services/api";

const usePostThumbsDown = () => {
  const { showNotification } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const postThumbsDown = async suggestedResponseRecordId => {
    setError(null);
    setLoading(true);

    try {
      await postSuggestedResponseThumbsDown({ suggestedResponseRecordId });

      setLoading(false);
      showNotification({
        title: "Success!",
        message: "The AI suggestion was marked as not helpful."
      });
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    postThumbsDown
  };
};

export default usePostThumbsDown;
