import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { securelyParseJson } from "./utils";

const storageKeyBase = "airbox:ai-suggested-message-used:";
const conversationIdPrefix = "conversation-id:";
const threadIdPrefix = "thread-id:";

/*
If they don't use the AI button -> we send 'message'
If they use the AI button –> we send 'message' and 'ai_generated_message'
If they use AI button, refresh the page, use a draft -> we send 'message' and 'ai_generated_message'
If they use AI button, refresh the page, don't use a draft -> we send 'message'

This is not a reducer mainly because users can use drafts.
*/
export const useLocalStorageSuggestedResponseTracker = () => {
  const { conversationId, threadId } = useParams();

  const prefixedId = conversationId
    ? `${conversationIdPrefix}${conversationId}`
    : `${threadIdPrefix}${threadId}`;
  const storageKey = `${storageKeyBase}${prefixedId}`;

  const getIsSuggestedResponseUsed = useCallback(
    () => securelyParseJson(window.localStorage.getItem(storageKey)),
    [storageKey]
  );

  const setIsSuggestedResponseUsed = useCallback(
    value => {
      window.localStorage.setItem(storageKey, JSON.stringify(value));
    },
    [storageKey]
  );

  const resetIsSuggestionsResponseUsed = useCallback(() => {
    window.localStorage.removeItem(storageKey);
  }, [storageKey]);

  const removeSuggestedResponseTracker = id => {
    window.localStorage.removeItem(
      `${storageKeyBase}${conversationIdPrefix}${id}`
    );
    window.localStorage.removeItem(`${storageKeyBase}${threadIdPrefix}${id}`);
  };

  return {
    getIsSuggestedResponseUsed,
    setIsSuggestedResponseUsed,
    resetIsSuggestionsResponseUsed,
    removeSuggestedResponseTracker
  };
};
